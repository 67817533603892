import { useEffect } from "react"
import { Drawer } from "components/ui/drawer/Drawer"
import { useDocuments, useDrawingById } from "hooks/drawings.hook"
import { useDrawerStore } from "store/drawer.store"
import { BandgeStatus } from "components/ui/bandges/BadgeStatus/BadgeStatus"
import { CloseIcon } from "components/ui/icons/close/CloseIcon"
import { H2, H3 } from "components/ui/typography/titles/Titles"
import { Tabs } from "components/ui/tabs/Tabs"
import { TabsProps } from "antd"
import { FileInfoDocuments } from "./layout/FileInfoDocuments/FileInfoDocuments"
import { FileInfoKeywords } from "./layout/FileInfoKeywords/FileInfoKeywords"
import { Skeleton } from "components/ui/skeleton/Skeleton"
import { FileInfoObjects } from "./layout/FileInfoObjects/FileInfoObjects"
import { useSearchParams } from "react-router-dom"
import { AxiosError } from "axios"
import style from "./FileInfo.module.scss"

const { drawerAllFiles, wrapper, header, content, status, closeIcon, closeButton, skeleton, warningMessage } = style

// Type guard to check if error is an AxiosError
const isAxiosError = (error: unknown): error is AxiosError => {
	return !!(error && (error as AxiosError).isAxiosError)
}

export const FileInfo = () => {
	const { isDrawerOpen, setDrawerClose, setDrawerOpen } = useDrawerStore()

	const [searchParams, setSearchParams] = useSearchParams()
	const drawerId = searchParams.get("drawingID")

	const {
		data: drawing,
		isPending: drawingIsPending,
		refetch: refetchDrawerInfo,
		error: drowingByIdError,
	} = useDrawingById(drawerId)
	const {
		data: documents,
		isPending: documentsIsPending,
		refetch: refetchDocuments,
		error: documentsError,
	} = useDocuments(drawerId)

	const documentsResponseStatus = isAxiosError(documentsError) ? documentsError.response?.status : null
	const drowingByIdResponseStatus = isAxiosError(drowingByIdError) ? drowingByIdError.response?.status : null

	const tabsItems: TabsProps["items"] = [
		{ key: "1", label: "Documents", children: <FileInfoDocuments documents={documents} /> },
		{
			key: "2",
			label: "Keywords",
			children: <FileInfoKeywords />,
			disabled: drawing?.status !== "processed",
		},
		{
			key: "3",
			label: "Objects",
			children: <FileInfoObjects />,
			disabled: drawing?.status !== "processed",
		},
	]

	// Fetch documents and drawer info
	useEffect(() => {
		if (drawerId) {
			refetchDocuments()
			refetchDrawerInfo()
			setDrawerOpen()
		}
	}, [refetchDocuments, drawerId, refetchDrawerInfo, setDrawerOpen])

	// Rreset drawer ID and close the drower
	const closeDrawerHandle = () => {
		setDrawerClose()
		setSearchParams({})
	}

	const renderContent = () => {
		if (drawingIsPending || documentsIsPending) {
			return (
				<div className={skeleton}>
					<Skeleton rows={18} />
				</div>
			)
		}

		if (drowingByIdResponseStatus === 403 || documentsResponseStatus === 403) {
			return <H3 className={warningMessage}>You don't have permission to access this resource.</H3>
		}

		if (drowingByIdError || documentsError) {
			return <H3 className={warningMessage}>Something went wrong, please try again later.</H3>
		}

		return <Tabs items={tabsItems} />
	}

	return (
		<Drawer width={1100} open={isDrawerOpen} onClose={closeDrawerHandle}>
			<div className={drawerAllFiles}>
				{drawingIsPending && documentsIsPending ? (
					<div className={skeleton}>
						<Skeleton rows={18} />
					</div>
				) : (
					<div className={wrapper}>
						<div className={header}>
							<div className={status}>
								<BandgeStatus type={drawing?.status} />
								<button className={closeButton} onClick={closeDrawerHandle}>
									<CloseIcon className={closeIcon} />
								</button>
							</div>
							<H2>{drawing?.name}</H2>
						</div>
						<div className={content}>{renderContent()}</div>
					</div>
				)}
			</div>
		</Drawer>
	)
}
