import { NavLink, useLocation } from "react-router-dom"
import { FileIcon } from "components/ui/icons/navbar/FileIcon"
import { ConfigurationIcon } from "components/ui/icons/navbar/ConfigurationIcon"
import { TeamIcon } from "components/ui/icons/navbar/TeamIcon"
import { Userbar } from "components/ui/Userbar/Userbar"
import { Logo } from "../logo/Logo"
import { usePaginationStore } from "store/table.store"
import { useUserProfile } from "hooks/user.hook"
import style from "./Navbar.module.scss"

const { NavbarStyle, wrapper, active } = style

export const Navbar = () => {
	const { data: userProfile } = useUserProfile()
	const { setCurrentPage } = usePaginationStore()
	const location = useLocation()
	const path = location.pathname

	// Set the pagination page to first
	const clickLogoHandle = () => {
		setCurrentPage(1)
	}

	return (
		<nav className={NavbarStyle}>
			<div className={wrapper}>
				{path === "/all-files" ? (
					<button onClick={clickLogoHandle}>
						<Logo />
					</button>
				) : (
					<NavLink to="all-files">
						<Logo />
					</NavLink>
				)}
				<ul>
					<li>
						<NavLink to="all-files" className={({ isActive }) => (isActive ? active : "")}>
							<FileIcon />
							<span>All files</span>
						</NavLink>
					</li>
					{userProfile?.role === "Admin" || userProfile?.role === "Super Admin" ? (
						<>
							<li>
								<NavLink to="configuration" className={({ isActive }) => (isActive ? active : "")}>
									<ConfigurationIcon />
									<span>Configurations</span>
								</NavLink>
							</li>
							<li>
								<NavLink to="team" className={({ isActive }) => (isActive ? active : "")}>
									<TeamIcon />
									<span>Team members</span>
								</NavLink>
							</li>
						</>
					) : null}
				</ul>
			</div>
			<Userbar />
		</nav>
	)
}
