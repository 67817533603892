import { useMutation, useQuery } from "@tanstack/react-query"
import userService from "services/user.service"
import type { TUserRole } from "types/user.type"

export const useUserProfile = (enabled?: boolean) => {
	return useQuery({
		queryKey: ["userProfile"],
		queryFn: () => userService.getUserProfile(),
		select: ({ data }) => data,
		enabled: enabled,
	})
}

export const useUsers = () => {
	return useQuery({
		queryKey: ["users"],
		queryFn: () => userService.getUsers(),
		select: ({ data }) => data.users,
		enabled: false,
	})
}

export const useChangeUserRole = () => {
	return useMutation({
		mutationKey: ["changeUserRole"],
		mutationFn: ({ id, newRole }: TUserRole) => userService.changeUserRole(id, newRole),
	})
}
