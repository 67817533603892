import { useMutation } from "@tanstack/react-query"
import authService from "services/auth.service"
import type { TLogin, TRegistrationData, TActivateUser } from "types/auth.type"

export const useLogin = () => {
	return useMutation({
		mutationKey: ["login"],
		mutationFn: (data: TLogin) => authService.login(data.email, data.password),
	})
}

export const useDestroyToken = () => {
	return useMutation({
		mutationKey: ["destroyToken"],
		mutationFn: () => authService.destroyToken(),
	})
}

export const useRegistration = () => {
	return useMutation({
		mutationKey: ["registration"],
		mutationFn: (data: TRegistrationData) =>
			authService.register(data.email, data.firstName, data.lastName, data.password, data.confirmPassword),
	})
}

export const useResendInvintation = () => {
	return useMutation({
		mutationKey: ["resendInvintation"],
		mutationFn: (email: string) => authService.resendInvintation(email),
	})
}

export const useActivateUser = () => {
	return useMutation({
		mutationKey: ["activateUser"],
		mutationFn: (data: TActivateUser) => authService.activateUser(data.id, data.token),
	})
}
