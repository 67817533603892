import { DateIcon } from "components/ui/icons/date/DateIcon"
import { formatDate } from "helpers/date"
import style from "./FileInfoDetails.module.scss"

const { fileInfoDetails, item, letter, block, descr, value } = style

type Props = {
	author: {
		firstName: string
		lastName: string
	}
	date: string
	lastProcessingDate: string
}

export const FileInfoDetails = ({ author, date, lastProcessingDate }: Props) => {
	return (
		<div className={fileInfoDetails}>
			<div className={item}>
				<div className={letter}>{author.firstName?.charAt(0).toUpperCase()}</div>
				<div className={block}>
					<span className={descr}>Uploaded by</span>
					<span className={value}>{author.firstName + " " + author.lastName}</span>
				</div>
			</div>
			<div className={item}>
				<DateIcon />
				<div className={block}>
					<span className={descr}>Uploaded</span>
					<span className={value}>{date && formatDate(date)}</span>
				</div>
			</div>
			{lastProcessingDate && (
				<div className={item}>
					<DateIcon />
					<div className={block}>
						<span className={descr}>Processed</span>
						<span className={value}>{formatDate(lastProcessingDate)}</span>
					</div>
				</div>
			)}
		</div>
	)
}
