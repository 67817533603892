import { Tabs as AntTabs } from "antd"
import type { TabsProps } from "antd"
import { TabsProvider } from "components/providers/Tabs.provider"

type Props = {
	items: TabsProps["items"]
}

export const Tabs = ({ items }: Props) => {
	return (
		<TabsProvider>
			<AntTabs type="card" items={items} size="large" />
		</TabsProvider>
	)
}
