export const DateIcon = () => {
	return (
		<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M5.83464 1.04297C3.18827 1.04297 1.04297 3.18827 1.04297 5.83464V14.168C1.04297 16.8143 3.18827 18.9596 5.83464 18.9596H14.168C16.8143 18.9596 18.9596 16.8143 18.9596 14.168V5.83464C18.9596 3.18827 16.8143 1.04297 14.168 1.04297H5.83464Z"
				fill="#CCDAFF"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M17.293 2.20211C16.454 1.47969 15.362 1.04297 14.168 1.04297H11.043V7.5013C11.043 7.7022 11.1395 7.89086 11.3025 8.00833C11.4655 8.1258 11.675 8.15776 11.8656 8.09423L14.168 7.32678L16.4703 8.09423C16.6609 8.15776 16.8704 8.1258 17.0334 8.00833C17.1964 7.89086 17.293 7.7022 17.293 7.5013V2.20211ZM11.8763 14.168C11.8763 13.8228 12.1561 13.543 12.5013 13.543H15.8346C16.1798 13.543 16.4596 13.8228 16.4596 14.168C16.4596 14.5131 16.1798 14.793 15.8346 14.793H12.5013C12.1561 14.793 11.8763 14.5131 11.8763 14.168Z"
				fill="#0047FF"
			/>
		</svg>
	)
}
