import { useCallback, useEffect, useState } from "react"
import { ButtonPrimary } from "components/ui/buttons/ButtonPrimary/ButtonPrimary"
import { Modal } from "components/ui/modal/Modal"
import { Input } from "components/ui/inputs/Input/Input"
import { InputProvider } from "components/providers/Input.provider"
import { useAddKeyword, useKeywordsColors } from "hooks/keywords.hook"
import { Spin } from "components/ui/spin/Spin"
import { useMessage } from "hooks/message.hook"
import type { TKeywordsColors, TAddNewKeywordData } from "types/keywords,type"
import { AxiosError } from "axios"
import style from "./AddKeywords.module.scss"

const {
	button,
	container,
	inputBlock,
	colorBlock,
	selectColor,
	colorItem,
	colorWrapper,
	buttonBlock,
	buttonCancel,
	spinnerContainer,
} = style

type Props = {
	refetchKeywords: () => void
}

export const AddKeywords = ({ refetchKeywords }: Props) => {
	const [modalOpen, setModalOpen] = useState(false)
	const [colorActive, setColorActive] = useState<number | null>()
	const [addNewKeywordData, setAddNewKeywordData] = useState<TAddNewKeywordData>({ keyword: "", colorId: null })
	const [saveButtonSubmitable, setSaveButtonSubmitable] = useState(true)

	const { error: errorMessage, success: successMessage } = useMessage()

	const { mutate: addNewKeywordMutate, isPending: isAddnewKeywordPending } = useAddKeyword()
	const { data: keywordsColors, isPending: keywordsColorsIsPending } = useKeywordsColors()

	const selectColorHandle = useCallback((id: number) => {
		setColorActive(id)
		setAddNewKeywordData((prev) => ({
			...prev,
			colorId: id,
		}))
	}, [])

	const addNewKeywordHandle = useCallback(() => {
		addNewKeywordMutate(addNewKeywordData, {
			onSuccess: () => {
				setAddNewKeywordData({ keyword: "", colorId: null })
				setColorActive(null)
				setModalOpen(false)
				successMessage("Keyword created")
				refetchKeywords()
			},
			onError: (e) => {
				if (e instanceof AxiosError) {
					const status = e.response?.status
					if (status === 409) {
						errorMessage("Keyword already exists")
					} else {
						errorMessage("Something went wrong, please try again later")
					}
				}
			},
		})
	}, [addNewKeywordData, addNewKeywordMutate, errorMessage, successMessage, refetchKeywords])

	const closeModalHandle = useCallback(() => {
		setModalOpen(false)
		setAddNewKeywordData({ keyword: "", colorId: null })
		setColorActive(null)
	}, [])

	// Validate if all fields are filled then enable the save button
	useEffect(() => {
		const allFieldsFilled = Object.values(addNewKeywordData).every((value) => value != "" && value != null)
		setSaveButtonSubmitable(allFieldsFilled)
	}, [addNewKeywordData])

	return (
		<>
			<ButtonPrimary className={button} onClick={() => setModalOpen(true)}>
				Create new keyword
			</ButtonPrimary>
			<Modal title="Create keyword" isModalOpen={modalOpen} setModalClose={closeModalHandle}>
				{keywordsColorsIsPending || isAddnewKeywordPending ? (
					<div className={spinnerContainer}>
						<Spin />
					</div>
				) : (
					<div className={container}>
						<div className={inputBlock}>
							<span>Name</span>
							<InputProvider>
								<Input
									value={addNewKeywordData.keyword}
									onChange={(e) =>
										setAddNewKeywordData((prev) => ({
											...prev,
											keyword: e.target.value,
										}))
									}
								/>
							</InputProvider>
						</div>
						<div className={colorBlock}>
							<span>Select color</span>
							<div className={selectColor}>
								{keywordsColors &&
									keywordsColors.map(({ colorId, keywordColor }: TKeywordsColors) => (
										<div
											className={colorWrapper}
											style={{ outline: colorActive === colorId ? `solid 2px ${keywordColor}` : "" }}
											key={colorId}
											onClick={() => selectColorHandle(colorId)}
                      data-testid="colorItem"
										>
											<div className={colorItem} style={{ backgroundColor: keywordColor }}></div>
										</div>
									))}
							</div>
						</div>
						<div className={buttonBlock}>
							{/* TODO: make request to backend by clicking on Save buttom */}
							<ButtonPrimary onClick={addNewKeywordHandle} submittable={saveButtonSubmitable}>
								Save
							</ButtonPrimary>
							<button onClick={closeModalHandle} className={buttonCancel}>
								Cancel
							</button>
						</div>
					</div>
				)}
			</Modal>
		</>
	)
}
