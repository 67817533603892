import { BandgeStatus } from "components/ui/bandges/BadgeStatus/BadgeStatus"
import { ButtonSecondary } from "components/ui/buttons/ButtonSecondary/ButtonSecondary"
import { FileIcon } from "components/ui/icons/navbar/FileIcon"
import { Progress } from "components/ui/progress/Progress"
import { Dropdown } from "components/ui/dropdown/Dropdown"
import { CustomFilter } from "components/pages/PageAllFiles/layout/TableAllFiles/layout/CustomFilter/CustomFilter"
import { ButtonOptions } from "components/ui/buttons/ButtonOptions/ButtonOptions"
import { Space } from "antd"
import { TableAllFilesHandlers } from "./TableAllFilesHandlers"
import { TableAllFilesUtils } from "./TableAllFilesUtils"
import { usePaginationStore } from "store/table.store"
import { useUserProfile } from "hooks/user.hook"
import type { ColumnsType } from "antd/es/table"
import type { TTable } from "types/table.type"
import type { FilterDropdownProps } from "antd/es/table/interface"
import style from "./TableAllFiles.module.scss"

interface FilterOption {
	text: string
	value: string
}

const { proccessButton, nameBlock, fileIcon } = style

export const TableAllFilesColumns = (refetchDrawings: () => void) => {
	const { startProcessingHandle } = TableAllFilesHandlers(refetchDrawings)
	const { getDropDownItems } = TableAllFilesUtils()
	const { data: userProfile } = useUserProfile(false)
	const { tableParams } = usePaginationStore()

	// Filters for Author column
	const authorFilters = [
		{
			text: "Me",
			value: "me",
		},
		{
			text: "Others",
			value: "other",
		},
	]

	// Custom filter
	const columnFilterProps = (dataIndex: string, filterOptions: FilterOption[]) => ({
		filterDropdown: (props: FilterDropdownProps) => (
			<CustomFilter
				{...props}
				filteredOptions={filterOptions}
				selectedKeys={props.selectedKeys}
				clearFilters={props.clearFilters || (() => {})}
				confirm={() => props.confirm()}
				dataIndex={dataIndex}
				setSelectedKeys={props.setSelectedKeys}
			/>
		),
	})

	const columns: ColumnsType<TTable> = [
		{
			title: "NAME",
			dataIndex: "name",
			key: "name",
			width: 450,
			render: (item) => (
				<div className={nameBlock}>
					<FileIcon className={fileIcon} /> {item}
				</div>
			),
		},
		{
			title: "AUTHOR",
			dataIndex: "author",
			key: "author",
			render: (record) => record.firstName + " " + record.lastName?.charAt(0) + ".",
			filters: authorFilters,
			defaultFilteredValue:
				tableParams.filter.author && tableParams.filter.author != "all" ? [tableParams.filter.author] : null,
			filterMultiple: false,
			// filterDropdown: () => <div>Some filter</div>,
			...columnFilterProps("author", authorFilters),
		},
		{
			title: "STATUS",
			dataIndex: "status",
			key: "status",
			render: (item) => <BandgeStatus type={item} />,
		},
		{
			title: "UPLOADED",
			dataIndex: "uploadDate",
			key: "uploadDate",
		},
		{
			title: "PROCESSED",
			dataIndex: "lastProcessingDate",
			key: "lastProcessingDate",
		},
		{
			title: "SIZE",
			dataIndex: "size",
			key: "size",
		},
		{
			title: "",
			dataIndex: "processing",
			key: "processing",
			width: 300,
			render: (item) =>
				item.status === "uploaded" && item.owner === "me" ? (
					<ButtonSecondary className={proccessButton} onClick={(e) => startProcessingHandle(item.id, e)}>
						Start processing
					</ButtonSecondary>
				) : item.status === "failed" && item.owner === "me" ? (
					<ButtonSecondary className={proccessButton} onClick={(e) => startProcessingHandle(item.id, e)}>
						Reprocess
					</ButtonSecondary>
				) : item.status === "processing" ? (
					<Progress progress={item.progress} />
				) : (
					""
				),
		},
		{
			title: "",
			dataIndex: "action",
			key: "action",
			render: (item) =>
				item?.owner === "me" ? (
					<div onClick={(e) => e.stopPropagation()} data-testid="dropdownMenu">
						<Dropdown items={getDropDownItems(item.id, item.status, item.fileName)}>
							<Space>
								<ButtonOptions />
							</Space>
						</Dropdown>
					</div>
				) : (
					""
				),
		},
	]

	// Remove author column for non-admin users
	const isAdmin = userProfile?.role === "Admin" || userProfile?.role === "Super Admin"
	const filteredColumns = isAdmin ? columns : columns.filter((column) => column.key !== "author")

	return filteredColumns
}
