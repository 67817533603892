import { ConfigProvider } from "antd"
import { ReactNode } from "react"

type Props = {
	children: ReactNode
}

export const CollapseBlueProvider = ({ children }: Props) => {
	return (
		<ConfigProvider
			theme={{
				token: {
					padding: 4,
				},
				components: {
					Collapse: {
						fontSizeLG: 24,
					},
				},
			}}
		>
			{children}
		</ConfigProvider>
	)
}
