import { H2 } from "components/ui/typography/titles/Titles"
import { InputSearch } from "components/ui/inputs/InputSearch/InputSearch"
import { KeywordsByAlphabet } from "./layout/KeywordsByAlphabet/KeywordsByAlphabet"
import { AddKeywords } from "./layout/AddKeywords/AddKeywords"
import { useKeywords } from "hooks/keywords.hook"
import { Skeleton } from "components/ui/skeleton/Skeleton"
import style from "./Keywords.module.scss"

const { keywordsStyle, top, skeletonContainer } = style

export const Keywords = () => {
	const { data: keywords, isPending: isKeywordsPending, refetch: refetchKeywords } = useKeywords()

	if (isKeywordsPending) {
		return (
			<div className={skeletonContainer}>
				<Skeleton rows={8} />
			</div>
		)
	}

	return (
		<div className={keywordsStyle}>
			<div className={top}>
				<H2>Keywords</H2>
				<AddKeywords refetchKeywords={refetchKeywords} />
			</div>
			<InputSearch placeholder="Search keyword" disabled={true} />
			<KeywordsByAlphabet keywords={keywords} refetchKeywords={refetchKeywords} />
		</div>
	)
}
