import { useMemo } from "react"
import { MenuProps } from "antd"
import { DeleteFileIcon } from "components/ui/icons/fileManagment/DeleteFileIcon"
import { ReprocessIcon } from "components/ui/icons/fileManagment/ReprocessIcon"
import { useDeleteConfirmModalStore } from "store/modal.store"
import { TableAllFilesHandlers } from "./TableAllFilesHandlers"
import type { TTable } from "types/table.type"
import style from "./TableAllFiles.module.scss"

const { dropDownLabel } = style

// Function to generate dropdown items based on item status
export const TableAllFilesUtils = (records?: TTable[]) => {
	const { trigerDeleteConfirmModal, setDeleteFile } = useDeleteConfirmModalStore()
	const { startProcessingHandle } = TableAllFilesHandlers()

	const getDropDownItems = (id: number, status: string, fileName: string): MenuProps["items"] => {
		const items: MenuProps["items"] = [
			{
				type: "divider",
			},
			{
				key: "2",
				danger: true,
				label: (
					<div className={dropDownLabel}>
						<DeleteFileIcon />
						<span>Delete</span>
					</div>
				),
				onClick: () => {
					trigerDeleteConfirmModal()
					setDeleteFile({ id: id, fileName: fileName })
				},
			},
		]

		if (status === "processed") {
			items.unshift({
				key: "1",
				label: (
					<div className={dropDownLabel}>
						<ReprocessIcon />
						<span>Reprocess</span>
					</div>
				),
				onClick: (e) => {
					e.domEvent.stopPropagation()
					startProcessingHandle(id)
				},
			})
		} else if (status === "processing" || status === "in_queue") {
			items.splice(0, items.length, {
				key: "3",
				label: <span>Cancel processing</span>,
				disabled: true,
			})
		}

		return items
	}
	// Initial table data
	const initialTableData = useMemo(() => {
		if (records) {
			return records.map((item) => ({
				id: item.id,
				key: item.id,
				author: {
					firstName: item.ownerFirstName,
					lastName: item.ownerLastName,
					owner: item.owner,
				},
				owner: item.owner,
				name: item.name,
				status: item.status,
				processing: {
					status: item.status,
					id: item.id,
					owner: item.owner,
				},
				lastProcessingDate: item.lastProcessingDate,
				uploadDate: item.uploadDate,
				size: item.size,
				ownerId: item.ownerId,
				comments: item.comments,
				action: {
					status: item.status,
					id: item.id,
					owner: item.owner,
					fileName: item.name,
				},
			}))
		}
		return []
	}, [records])

	return { getDropDownItems, initialTableData }
}
