import { create } from "zustand"
import { TFileModal, TDeleteConfirmModal } from "types/modal.type"

export const useFileModalStore = create<TFileModal>((set) => ({
	isFileModalOpen: false,
	triggerFileModal: () => set((state) => ({ isFileModalOpen: !state.isFileModalOpen })),
	setFileModalClose: () => set(() => ({ isFileModalOpen: false })),
}))

export const useDeleteConfirmModalStore = create<TDeleteConfirmModal>((set) => ({
	isDeleteConfirmModalOpen: false,
	trigerDeleteConfirmModal: () => set((state) => ({ isDeleteConfirmModalOpen: !state.isDeleteConfirmModalOpen })),
	setDeleteConfirmDodalClose: () => set(() => ({ isDeleteConfirmModalOpen: false })),

	deleteFile: { id: 0, fileName: "" },
	setDeleteFile: (file) => set(() => ({ deleteFile: file })),
}))
