import { ConfigProvider, Form, Input } from "antd"
import { ButtonPrimary } from "components/ui/buttons/ButtonPrimary/ButtonPrimary"
import { useLogin } from "hooks/auth.hook"
import { useCredentialsStore } from "store/auth.store"
import { H2 } from "components/ui/typography/titles/Titles"
import { useEffect, useState } from "react"
import { AxiosError } from "axios"
import { useMessage } from "hooks/message.hook"
import style from "./FormAuth.module.scss"

const { formAuth, title, button } = style

export const FormAuth = () => {
	const [form] = Form.useForm()
	const values = Form.useWatch([], form)

	const [submittable, setSubmittable] = useState(false)

	const { setCredentials } = useCredentialsStore()
	const loginMutation = useLogin()
	const { error, success } = useMessage()

	const submitFormHandle = () => {
		loginMutation.mutate(values, {
			onSuccess: (data) => {
				setCredentials(data?.data.auth_token)
				setSubmittable(false)
				success("You are logged in")
			},
			onError: (e) => {
				if (e instanceof AxiosError) {
					const status = e.response?.status
					if (status === 400) {
						error("Unable to log in with provided credentials")
					} else {
						error("Something went wrong, please try again later")
					}
				}
			},
		})
	}

	useEffect(() => {
		form.validateFields({ validateOnly: true }).then(
			() => {
				setSubmittable(true)
			},
			() => {
				setSubmittable(false)
			},
		)
	}, [form, values])

	return (
		<div className={formAuth} data-testid="FormAuth">
			<H2 className={title}>Welcome back!</H2>
			<ConfigProvider
				theme={{
					token: {
						marginLG: 10,
						borderRadiusLG: 12,
						fontSize: 18,
						colorTextPlaceholder: "rgba(136, 141, 166, 1)",
					},
					components: {
						Input: {
							paddingBlockLG: 10,
						},
					},
				}}
			>
				<Form form={form} onFinish={submitFormHandle} size="large">
					<Form.Item name="email" rules={[{ required: true, type: "email" }]}>
						<Input placeholder="Email" />
					</Form.Item>
					<Form.Item name="password" rules={[{ required: true }]}>
						<Input.Password placeholder="Password" />
					</Form.Item>
					<ButtonPrimary submittable={submittable} className={button}>
						Log in
					</ButtonPrimary>
				</Form>
			</ConfigProvider>
		</div>
	)
}
