import { H1 } from "components/ui/typography/titles/Titles"
import { ButtonSecondaryForwardRef } from "components/ui/buttons/ButtonSecondary/ButtonSecondary"
import { ButtonPrimary } from "components/ui/buttons/ButtonPrimary/ButtonPrimary"
import { Tooltip } from "components/ui/tooltip/Tooltip"
import { copyToClipboard } from "helpers/utils.helper"
import { useMessage } from "hooks/message.hook"
import style from "./PageTeamHeader.module.scss"

const { pageTeamHeader, wrapper, buttons, text } = style

export const PageTeamHeader = () => {
	const { success } = useMessage()
	const origin = window.location.origin

	const onCopyLinkHandle = () => {
		const inviteLink = `${origin}/signup`
		copyToClipboard(inviteLink)
		success("The link has been copied")
	}

	return (
		<div className={pageTeamHeader}>
			<div className={wrapper}>
				<H1>Team members</H1>
				<div className={buttons}>
					<Tooltip text="Invite new people with this link">
						<ButtonSecondaryForwardRef onClick={onCopyLinkHandle}>Copy invitation link</ButtonSecondaryForwardRef>
					</Tooltip>
					<ButtonPrimary submittable={false}>Invite People</ButtonPrimary>
				</div>
			</div>
			<p className={text}>Here you can invite new users, deactivate, or delete them.</p>
		</div>
	)
}
