import { Form, Input } from "antd"
import { ButtonPrimary } from "components/ui/buttons/ButtonPrimary/ButtonPrimary"
import { H2 } from "components/ui/typography/titles/Titles"
import { useEffect, useState } from "react"
import { AuthProvider } from "components/providers/Auth.provider"
import { useRegistrationStore } from "store/auth.store"
import { useRegistrationStepsStore } from "store/auth.store"
import style from "./UserData.module.scss"

const { title, blue, firstLastName } = style

export const UserData = () => {
	const [form] = Form.useForm()
	const values = Form.useWatch([], form)

	const [submittable, setSubmittable] = useState(false)

	const setRegistration = useRegistrationStore((state) => state.setRegistration)
	const { setStep } = useRegistrationStepsStore()

	useEffect(() => {
		setRegistration({
			email: values?.email,
			firstName: values?.firstName,
			lastName: values?.lastName,
		})
	}, [setRegistration, values?.email, values?.firstName, values?.lastName])

	useEffect(() => {
		form.validateFields({ validateOnly: true }).then(
			() => {
				setSubmittable(true)
			},
			() => {
				setSubmittable(false)
			},
		)
	}, [form, values])

	return (
		<>
			<H2 className={title} data-tesid="userData">
				You have been invited to <span className={blue}>Stevens Workspace!</span>
			</H2>
			<AuthProvider>
				<Form form={form} size="large">
					<Form.Item
						name="email"
						rules={[
							{
								required: true,
								// validator: (_, value) => {
								// 	if (/\b[A-Za-z0-9._%+-]+@stevensind\.com\b/.test(value)) {
								// 		return Promise.resolve()
								// 	}
								// 	return Promise.reject(new Error("Email is invalid"))
								// },
							},
						]}
					>
						<Input placeholder="Email" />
					</Form.Item>
					<div className={firstLastName}>
						<Form.Item name="firstName" rules={[{ required: true, message: "Please enter First Name" }]}>
							<Input placeholder="First name" />
						</Form.Item>
						<Form.Item name="lastName" rules={[{ required: true, message: "Please enter Last Name" }]}>
							<Input placeholder="Last name" />
						</Form.Item>
					</div>
					<ButtonPrimary onClick={() => setStep(2)} submittable={submittable}>
						Continue
					</ButtonPrimary>
				</Form>
			</AuthProvider>
		</>
	)
}
