import { ReactNode } from "react"
import { Tooltip as AntTooltip } from "antd"
import { TooltipProvider } from "components/providers/Tooltip.provider"

type Props = {
	children: ReactNode
	text: string
	color?: string
}

export const Tooltip = ({ children, text, color = "#F2F3F7" }: Props) => {
	return (
		<TooltipProvider>
			<AntTooltip title={text} color={color}>
				{children}
			</AntTooltip>
		</TooltipProvider>
	)
}
