import { Categories } from "../Categories/Categories"
import { Keywords } from "../Keywords/Keywords"

export const ConfigurationContent = ({ index }: { index: number }) => {
	switch (index) {
		case 0:
			return <Keywords />
		case 1:
			return <Categories />

		default:
			return <Keywords />
	}
}
