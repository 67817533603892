const capitalizeFirstLetter = (string: string) => {
	return string.charAt(0).toUpperCase() + string.slice(1)
}

const removeApiVersion = (url: string): string => {
	return url.replace("/api/v1/", "/")
}

const copyToClipboard = (str: string) => {
	navigator.clipboard.writeText(str)
}

export { capitalizeFirstLetter, removeApiVersion, copyToClipboard }
