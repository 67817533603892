import { UserData } from "../UserData/UserData"
import { UserPassword } from "../UserPassword/UserPassword"
import { useRegistrationStepsStore } from "store/auth.store"
import { CheckEmail } from "../CheckEmail/CheckEmail"
import style from "./FormRegistration.module.scss"

const { formRegistration } = style

export const FormRegistration = () => {
	const { step } = useRegistrationStepsStore()

	switch (step) {
		case 1:
			return (
				<div className={formRegistration} data-testid="userData">
					<UserData />
				</div>
			)

		case 2:
			return (
				<div className={formRegistration} data-testid="userPassword">
					<UserPassword />
				</div>
			)

		case 3:
			return (
				<div className={formRegistration} data-testid="checkEmail">
					<CheckEmail />
				</div>
			)
	}
}
