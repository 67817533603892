import { H2 } from "components/ui/typography/titles/Titles"
import style from "./ErrorCheck.module.scss"

const { title, text, contact } = style

export const ErrorCheck = () => {
	return (
		<>
			<H2 className={title}>Oops, we're sorry...</H2>
			<p className={text}>Unfortunately, your account could not be activated.</p>
			<a className={contact} href="mailto: someemail@some.com">
				Contact us
			</a>
		</>
	)
}
