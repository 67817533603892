import { H2 } from "components/ui/typography/titles/Titles"
import style from "./Categories.module.scss"

const { categories, title } = style

export const Categories = () => {
	return (
		<div className={categories}>
			<H2 className={title}>Coming soon</H2>
		</div>
	)
}
