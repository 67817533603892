import { useEffect, useState } from "react"
import { InputSearch } from "components/ui/inputs/InputSearch/InputSearch"
import { Keyword } from "components/pages/PageConfiguration/layout/Keywords/layout/Keyword/Keyword"
import { Skeleton } from "components/ui/skeleton/Skeleton"
import { useDrawingKeywords } from "hooks/drawings.hook"
import { useSearchParams } from "react-router-dom"
import style from "./FileInfoKeywords.module.scss"

const { fileInfoKeywords, keywordsByAlphabet, letterBox, letter, keywordsBox, skeleton, message, search } = style

type TKeywordDetails = {
	keywordId: number
	keywordColor: string
	textColor: string
	colorPriority?: number
	colorName?: string
	colorId?: number
}

type TKeywords = Record<string, TKeywordDetails[]>

export const FileInfoKeywords = () => {
	const [alphabet, setAlphabet] = useState<string[]>([])
	const [isKeywordsEmpty, setIsKeywordsEmpty] = useState<boolean>()
	const [searchParams] = useSearchParams()
	const drawerId = searchParams.get("drawingID")

	const {
		data: drawingKeywords,
		isPending: isKeywordsPending,
		isError: errorFetchingKewords,
	} = useDrawingKeywords(drawerId)
	const keywords = drawingKeywords as TKeywords

	// Set the keywords from API
	useEffect(() => {
		if (keywords) {
			const alphabet = Object.keys(keywords)
			setAlphabet(alphabet)
		}
	}, [keywords])

	// Check if Keywords empty
	useEffect(() => {
		if (alphabet) {
			const empty = Object.keys(alphabet).length === 0
			setIsKeywordsEmpty(empty)
		}
	}, [alphabet])

	// Display skeleton if request is pending
	if (isKeywordsPending) {
		return (
			<div className={skeleton} data-testid="keywordsSkeleton">
				<Skeleton rows={12} />
			</div>
		)
	}

	return (
		<div className={fileInfoKeywords}>
			{isKeywordsEmpty || errorFetchingKewords ? (
				""
			) : (
				<div className={search}>
					<InputSearch disabled={true} />
				</div>
			)}
			<div className={keywordsByAlphabet}>
				{isKeywordsEmpty && !errorFetchingKewords ? (
					<span className={message}>No keywords found</span>
				) : errorFetchingKewords ? (
					<span className={message}>Something went wrong, please try again later</span>
				) : (
					alphabet?.map((letterItem) => (
						<div className={letterBox} key={letterItem}>
							<span className={letter}>{letterItem.toUpperCase()}</span>
							<div className={keywordsBox}>
								{keywords[letterItem] &&
									Object.entries(keywords[letterItem]).map(([keywordName, keywordDetails]) => (
										<div key={keywordDetails.keywordId}>
											<Keyword
												style={{ backgroundColor: keywordDetails.keywordColor, color: keywordDetails.textColor }}
											>
												{keywordName}
											</Keyword>
										</div>
									))}
							</div>
						</div>
					))
				)}
			</div>
		</div>
	)
}
