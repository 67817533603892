import { FileIcon } from "components/ui/icons/navbar/FileIcon"
import { EyeIcon } from "components/ui/icons/eyeIcon/EyeIcon"
import { ButtonPrimary } from "components/ui/buttons/ButtonPrimary/ButtonPrimary"
import { DownloadIcon } from "components/ui/icons/download/DownloadIcon"
import type { TStopPropagation } from "types/default.type"
import style from "./FileInfoCard.module.scss"

const {
	fileInfoCard,
	wrapper,
	top,
	type,
	note,
	bottom,
	name,
	size,
	hovered,
	preview,
	button,
	previewText,
	downloadIcon,
	disableHover,
} = style

type Props = {
	file: {
		type: string
		note: string
		name: string
		size: string
		id: number
	}
	downloadFile: (e: TStopPropagation) => void
	openFilePreview: (e: TStopPropagation) => void
}

export const FileInfoCard = ({ file, downloadFile, openFilePreview }: Props) => {
	const fileType = file?.type

	const cardClickHandle = (e: TStopPropagation) => {
		if (fileType === "PDF") {
			openFilePreview(e)
		}
	}

	return (
		<div className={fileInfoCard} onClick={cardClickHandle}>
			<div className={wrapper}>
				<div className={top}>
					<FileIcon />
					<span className={type}>{file?.type}</span>
					<span className={note}>{file?.note}</span>
				</div>
				<div className={bottom}>
					<span className={name}>{file?.name}</span>
					<span className={size}>{file?.size}</span>
				</div>
			</div>
			<div className={`${hovered} ${fileType === "PDF" ? "" : disableHover}`}>
				<div className={preview}>
					<EyeIcon />
					<span className={previewText}>Preview</span>
				</div>
				<ButtonPrimary className={button} onClick={downloadFile}>
					Download <DownloadIcon className={downloadIcon} />
				</ButtonPrimary>
			</div>
		</div>
	)
}
