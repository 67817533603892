import { useDeleteDrawing } from "hooks/drawings.hook"
import { useMessage } from "hooks/message.hook"
import { useProcessFiles } from "hooks/processFiles.hook"
import { useDeleteConfirmModalStore } from "store/modal.store"
import { useMessagesDataStore } from "store/nats.store"
import { usePaginationStore } from "store/table.store"
import { useSearchParams } from "react-router-dom"
import type { TStopPropagation } from "types/default.type"
import type { TTable } from "types/table.type"
import type { TableProps } from "antd/es/table"

export const TableAllFilesHandlers = (refetchDrawings?: () => void) => {
	const { mutate: processFilesMutate } = useProcessFiles()
	const { error: userErrorMessage, success: userSuccessMessage } = useMessage()
	const { mutate: deleteDrawing } = useDeleteDrawing()
	const [, setSearchParams] = useSearchParams()

	const { setMessagesData } = useMessagesDataStore()
	const { setDeleteConfirmDodalClose, deleteFile } = useDeleteConfirmModalStore()
	const { setCurrentPage, setFilter } = usePaginationStore()

	// Start processing handle
	const startProcessingHandle = (id: number, e?: TStopPropagation) => {
		if (e) e.stopPropagation()

		processFilesMutate(id, {
			onSuccess: () => {
				setMessagesData(id, {
					status: "in_queue",
					progress: 0,
				})
			},
			onError: () => {
				userErrorMessage("Something went wrong, please try again later")
			},
		})
	}

	// Handle row click for opening the drawer
	const onRowHandle = (record: TTable) => ({
		onClick: () => {
			const recordId = record.id.toString()
			setSearchParams({ drawingID: recordId })
		},
		onMouseEnter: () => {
			document.body.style.cursor = "pointer"
		},
		onMouseLeave: () => {
			document.body.style.cursor = "default"
		},
	})

	// Delete the file on delete confirmation
	const deleteFileHandle = () => {
		deleteDrawing(deleteFile.id, {
			onSuccess: () => {
				if (refetchDrawings) refetchDrawings()
				userSuccessMessage(`${deleteFile.fileName} is succesfully deleted`)
				setDeleteConfirmDodalClose()
			},
			onError: () => {
				userErrorMessage("Something went wrong, please try again later")
				setDeleteConfirmDodalClose()
			},
		})
	}

	const onChangeTableHandle: TableProps<TTable>["onChange"] = (pagination, filters, _sorter, extra) => {
		const author = filters.author && filters.author[0]
		const currentPage = pagination.current!
		const onChangeAction = extra.action

		switch (onChangeAction) {
			case "paginate":
				setCurrentPage(currentPage)
				break

			case "filter":
				if (author === null) {
					// Handle null case: possibly reset the filter
					setFilter("all")
				} else if (typeof author === "string") {
					setFilter(author)
				}
				break
		}
	}

	return { startProcessingHandle, onRowHandle, deleteFileHandle, onChangeTableHandle }
}
