import { Collapse as AntCollapse } from "antd"
import { CollapseBlueProvider } from "components/providers/Collapse.provider"
import { CollapseIcon } from "../icons/collapseIcon/CollapseIcon"
import type { CollapseProps } from "antd"

type Props = {
	items: CollapseProps["items"]
	bordered?: boolean
	size?: "small" | "middle" | "large"
	ghost?: boolean
}

export const Collapse = ({ items, bordered = false, size = "large", ghost = true }: Props) => {
	return (
		<CollapseBlueProvider>
			<AntCollapse
				items={items}
				bordered={bordered}
				size={size}
				ghost={ghost}
				expandIcon={({ isActive }) => <CollapseIcon rotate={isActive ? 90 : 0} />}
			/>
		</CollapseBlueProvider>
	)
}
