import { axiosInstance } from "api/axios.instance"
class DrawingsService {
	static BASE_URL = "/drawings"

	async getDrawings(page: number, owner: string | null) {
		const ownerParam = owner === null ? "" : `&owner=${owner}`
		return axiosInstance.get(`${DrawingsService.BASE_URL}/?page=${page}${ownerParam}`)
	}

	async getDrawingById(id: string | null) {
		return axiosInstance.get(`${DrawingsService.BASE_URL}/${id}/`)
	}

	async getDocuments(id: string | null) {
		return axiosInstance.get(`${DrawingsService.BASE_URL}/${id}/documents`)
	}

	async getDrawingKeywords(id: string | null) {
		return axiosInstance.get(`${DrawingsService.BASE_URL}/${id}/keywords`)
	}

	async getDrawingObjects(id: string | null) {
		return axiosInstance.get(`${DrawingsService.BASE_URL}/${id}/objects-found`)
	}

	async delDrawing(id: number | undefined) {
		return axiosInstance.delete(`${DrawingsService.BASE_URL}/${id}/`)
	}
}

export default new DrawingsService()
