import { useMutation, useQuery } from "@tanstack/react-query"
import drawingService from "services/drawing.service"
import { usePaginationStore } from "store/table.store"

export const useDrawings = () => {
	const { tableParams } = usePaginationStore()
	const page = tableParams?.pagination.current
	const owner = tableParams?.filter.author

	return useQuery({
		queryKey: ["drawings", page, owner],
		queryFn: () => drawingService.getDrawings(page, owner),
		select: ({ data }) => data,
	})
}

export const useDrawingById = (id: string | null) => {
	return useQuery({
		queryKey: ["drawingById", id],
		queryFn: () => drawingService.getDrawingById(id),
		select: ({ data }) => data,
		enabled: !!id,
	})
}

export const useDocuments = (id: string | null) => {
	return useQuery({
		queryKey: ["documents", id],
		queryFn: () => drawingService.getDocuments(id),
		select: ({ data }) => data,
		enabled: false,
	})
}

export const useDrawingKeywords = (id: string | null) => {
	return useQuery({
		queryKey: ["drawingKeywords", id],
		queryFn: () => drawingService.getDrawingKeywords(id),
		select: ({ data }) => data,
	})
}

export const useDrawingObjects = (id: string | null) => {
	return useQuery({
		queryKey: ["drawingObjects", id],
		queryFn: () => drawingService.getDrawingObjects(id),
		select: ({ data }) => data,
	})
}

export const useDeleteDrawing = () => {
	return useMutation({
		mutationKey: ["deleteDrawing"],
		mutationFn: (id: number | undefined) => drawingService.delDrawing(id),
	})
}
